<template>
  <v-container class="pa-0 ma-0">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Document -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="2" lg="2" md="4" sm="4"
              cols="12"
            >
              <mb-input-cpf
                v-model="contratante.document"
                name="cntpfl_cpf" label="CPF"
                rules="required"
              />
            </v-col>

          </v-row>
          <!-- Name -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="8" lg="8" md="8" sm="8"
              cols="12" class="py-0"
            >
              <mb-input-text
                v-model="contratante.name"
                name="cntpfl_name" label="Nome"
                rules="required|max:60"
              />
            </v-col>
          </v-row>
          <!-- Email -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="8" lg="8" md="8" sm="8"
              cols="12" class="py-0">
              <mb-input-text
                v-model.trim="contratante.email"
                name="cntpfl_email" label="Email" rules="required"
              />
            </v-col>
          </v-row>
          <!-- Phones -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="4" lg="4" md="4" sm="4"
              cols="12">
              <mb-input-phone
                v-model="contratante.mobile"
                name="cntpfl_mobile" label="Celular" rules="required|phone"
              />
            </v-col>
            <v-col
              xl="4" lg="4" md="4" sm="4"
              cols="12">
              <mb-input-phone
                v-model="contratante.phone"
                name="cntpfl_phone" label="Telefone"
              />
            </v-col>
          </v-row>
          <!-- Birthday/Genre -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="4" lg="4" md="4" sm="4"
              cols="12"
            >
              <mb-date
                v-model="contratante.birthdate"
                name="cpny_birthdate"
                label="Data de Nascimento"
                rules="required"
                :allowedDates="(vl) => { return $moment(vl).isBefore($moment()) }"
              />
            </v-col>
            <v-col
              xl="4" lg="4" md="4" sm="4"
              cols="12"
            >
              <mb-select
                v-model="contratante.genre"
                :items="sexItems"
                itemText="text"
                itemValue="vl"
                name="cpny_sex"
                label="Sexo"
              />
            </v-col>
          </v-row>

          <v-divider />

          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <address-form v-model="contratante" />
            </v-col>
          </v-row>

        </form>
      </v-card-text>

      <v-card-actions class="mt-0">
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12" class="mt-0">
            <v-alert v-if="message" text :type="message.type" class="mt-0">
              {{message.txt}}
            </v-alert>
          </v-col>
          <v-col
            xl="3" lg="3" md="3" sm="3"
            cols="12" class="py-0">
            <mb-text-button
              name="prd_btn_save" label="Salvar"
              :disabled="invalid"
              :loading="saveBtnLoading"
              class="ml-2 mb-2"
              @click="save"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </ValidationObserver>
  </v-container>

</template>

<script>
import { ValidationObserver } from 'vee-validate';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCpf from '@/components/general/inputs/MbInputCpf';
import MbDate from '@/components/general/inputs/MbDate';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    MbInputCpf,
    MbSelect,
    MbDate,
    MbTextButton,
    AddressForm,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      contratante: {
        document: null,
        name: null,
        slug: null,
        contact: null,
        email: null,
        phone: null,
        mobile: null,
        birthdate: null,
        genre: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,

        type: 'pf',
      },
      message: null,
      sexItems: [
        { vl: 'female', text: 'Feminino' },
        { vl: 'male', text: 'Masculino' },
      ],
    };
  },
  computed: {
  },
  watch: {
    contratante: {
      handler() {
        if (this.contratante.type === 'pf') {
          this.contratante.slug = this.contratante.name;
          this.contratante.contact = this.contratante.name;
        }
      },
      deep: true,
    },
  },
  created() {
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate();

      const params = {};

      if (isValid) {
        params.parentId = this.params.se || this.params.e;
        params.type = (this.params.se) ? 'subestipulante' : 'estipulante';
        params.brokerId = this.params.b;
        params.brokersId = this.params.bs;
        params.userId = this.params.u;
        params.withoutAdhesion = this.params.withoutAdhesion;
        params.planId = this.params.p;
        params.contratante = this.contratante;

        this.saveBtnLoading = true;

        this.$store
          .dispatch('contratante/createFromLink', params)
          .then((resp) => {
            console.log('ok');
            this.contratante = resp;
            this.message = {
              type: 'success',
              txt: 'Cadastro salvo com sucesso!',
            };
            this.$emit('input', this.contratante);
            this.$emit('saved');
          })
          .catch((err) => {
            console.error(err.response.data.message);

            const msg = {
              type: 'error',
              txt: 'Erro ao salvar cadastro!',
            };

            if (err.response && err.response.data.message && err.response.data.message) {
              msg.txt = err.response.data.message;
            }
            this.message = msg;
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
  },
};
</script>
