<template>
  <v-container class="pa-0 ma-0">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Cadastro
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Dependente
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3">
          Termos
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" step="4">
          Pagamento
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="5">
          Fim
        </v-stepper-step>

      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <cadastre-form-link v-model="contratante" :params="params" @saved="cadastreSaved" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <dependent-list-link v-if="contratante" :titularId="contratante.userId" />
          <v-btn color="primary" @click="e1 = 3" :disabled="!contratante">Continuar</v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <term-link @accept="e1 = 4;"/>
        </v-stepper-content>

        <v-stepper-content step="4">
          <subscription-link
            v-if="contratante"
            :contratante="contratante"
            :params="params"
            :showing="e1 == 4"
            @created="createdSubscription" />
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-row dense no-gutters class="py-5 justify-center">
            <v-col
              xs="3" sm="3" md="3" lg="3"
              xl="3" cols="12" />

            <v-col
              xs="5" sm="5" md="5" lg="5"
              xl="5" cols="12" class="">

              <v-row dense no-gutters class="px-2 py-5 text-h5 justify-center">
                  <v-icon large color="green darken-2" class="px-3">mdi-check-decagram</v-icon>
                  <div>Assinatura criada com sucesso!</div>
              </v-row>

              <v-row dense no-gutters class="py-5 justify-center" >
                Você receberá um email com os dados de acesso!
              </v-row>

              <v-row
                dense no-gutters class="py-5 justify-center"
                >

                <v-btn
                  v-if="subscription && subscription.invoice"
                  color="primary"
                  class="ml-5"
                  @click="invoiceDownload"
                >
                  Abrir a Fatura
                </v-btn>
              </v-row>
            </v-col>

             <v-col
              xs="3" sm="3" md="3" lg="3"
              xl="3" cols="12" />
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import CadastreFormLink from '@/components/contratante/pf/link/CadastreFormLink';
import DependentListLink from '@/components/contratante/pf/link/DependentListLink';
import TermLink from '@/components/contratante/pf/link/TermLink';
import SubscriptionLink from '@/components/contratante/pf/link/SubscriptionLink';

export default {
  components: {
    CadastreFormLink,
    DependentListLink,
    TermLink,
    SubscriptionLink,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      e1: 1,
      loading: false,
      saveBtnLoading: false,
      contratante: null,
      subscription: null,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    cadastreSaved() {
      this.e1 = 2;
    },
    createdSubscription(subscription) {
      this.subscription = subscription;
      this.e1 = 5;
    },
    invoiceDownload() {
      const anchor = document.createElement('a');
      anchor.href = this.subscription.invoice.secure_url;
      anchor.target = '_blank';
      anchor.download = 'fature';
      anchor.click();
    },
  },
};
</script>
