<template>
  <v-container class="pa-0 ma-0">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Document -->
          <v-row dense>
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12"
            >
              <mb-input-cnpj-verify
                v-model="contratante.document"
                name="cpny_cnpj" label="CNPJ"
                rules="required"
                @valided="(valid) => { cnpjVerified = valid;}"
                @company="handleCompany"
              />
            </v-col>
          </v-row>
          <!-- Name -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="contratante.name"
                name="cpf_name" label="Nome"
                rules="required|max:150"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="contratante.slug"
                name="cpny_slug" label="Nome Divulgação"
                rules="required|max:50"
              />
            </v-col>
          </v-row>
          <!-- Email -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-text
                v-model="contratante.contact"
                name="cpny_contact" label="Contato"
                rules="required|max:50"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0">
              <mb-input-text
                v-model.trim="contratante.email"
                name="cpf_email" label="Email" rules="required"
              />
            </v-col>
          </v-row>
          <!-- Phones -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="contratante.mobile"
                name="cpf_mobile" label="Celular" rules="required|phone"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="contratante.phone"
                name="cpf_phone" label="Telefone"
              />
            </v-col>
          </v-row>
          <!-- ============== Address ============== -->
          <v-divider />

          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <address-form v-model="contratante"/>
            </v-col>
          </v-row>

        </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="invalid || !cnpjVerified"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
        <mb-text-button
          name="prd_btn_cancel" label="Cancelar" color="accent"
          class="mb-2" @click="cancel"
        />
      </v-card-actions>
    </ValidationObserver>

  </v-container>

</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
// import MbInputCnpj from '@/components/general/inputs/MbInputCnpj';
import MbInputCnpjVerify from '@/components/general/inputs/MbInputCnpjVerify';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    // MbInputCnpj,
    MbInputCnpjVerify,
    MbTextButton,
    AddressForm,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      contratante: null,
      defaultContratante: {
        document: null,
        name: null,
        slug: null,
        contact: null,
        email: null,
        phone: null,
        mobile: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,

        type: 'pj',
      },
      cnpjVerified: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
  watch: {
    value(vl) {
      this.contratante = vl || JSON.parse(JSON.stringify(this.defaultContratante));
      this.$refs.observer.reset();
    },
  },
  created() {
    this.contratante = this.value || JSON.parse(JSON.stringify(this.defaultContratante));
  },
  destroyed() {
    this.contratante = JSON.parse(JSON.stringify(this.defaultContratante));
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate();

      const params = {};

      if (isValid) {
        params.parentId = this.params.se || this.params.e;
        params.type = (this.params.se) ? 'subestipulante' : 'estipulante';
        params.brokerId = this.params.b;
        params.brokersId = this.params.bs;
        params.userId = this.params.u;
        params.withoutAdhesion = this.params.withoutAdhesion;
        params.planId = this.params.p;
        params.contratante = this.contratante;

        this.saveBtnLoading = true;
        this.$store
          .dispatch('contratante/createFromLink', params)
          .then(() => {
            this.contratante = this.defaultContratante;
            bus.$emit('message', {
              message: 'Contratante criado com sucesso!',
              type: 'success',
            });
            this.$refs.observer.reset();
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    cancel() {
      this.$emit('cancel');
      this.contratante = JSON.parse(JSON.stringify(this.defaultContratante));
      this.$refs.observer.reset();
    },
    handleCompany(c) {
      if (c) {
        this.contratante = c;
        this.contratante.type = 'pj';
        delete this.contratante.id;
        delete this.contratante.userId;
        delete this.contratante.paymentAccountStatus;
        delete this.contratante.paymentAccountFeedback;
        delete this.contratante.updatedBy;
        delete this.contratante.updatedAt;
      } else {
        this.contratante = this.defaultContratante;
      }
      this.$refs.observer.reset();
    },
  },
};
</script>
