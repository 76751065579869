<template>
  <v-container fluid>
    <v-progress-linear indeterminate :active="loading" />

    <v-row dense no-gutters>
      <v-col
        cols="12" sm="12" md="12" lg="4"
        xl="4" class="pa-3 ma-0 mb-2 mr-1"
      >
        <v-img contain max-height="190" src="@/assets/logo_branca.png" />
      </v-col>

      <v-col
        colss="12" sm="12" md="12" lg="7"
        xl="7" class="pa-0 ma-0"
      >
        <v-card class="elevation-1">
          <v-card-title>
            <span class="headline mb-0">Novo Contratante</span>
          </v-card-title>

          <contratante-pj-form-link
            v-if="paramsObj.t == 'pj'"
            v-model="contratante"
            :params="paramsObj" />

          <contratante-pf-form-link
            v-else
            v-model="contratante"
            :params="paramsObj" />

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContratantePjFormLink from '@/components/contratante/ContratantePjFormLink';
import ContratantePfFormLink from '@/components/contratante/ContratantePfFormLink';

export default {
  components: {
    ContratantePjFormLink,
    ContratantePfFormLink,
  },
  data: () => ({
    loading: false,
    userId: null,
    estipulante: null,
    contratante: null,
    paramsObj: null,
  }),
  created() {
    const params = this.$route.params.p; // console.log(params);
    const paramsDecodedB64 = window.atob(decodeURI(params)); // console.log(paramsDecodedB64);
    this.paramsObj = JSON.parse(paramsDecodedB64); // console.log(this.paramsObj);

    this.getEstipulante();
  },
  methods: {
    getEstipulante() {
      this.$store
        .dispatch('estipulante/oneLink', this.paramsObj.e)
        .then((estipulante) => {
          this.estipulante = estipulante;
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
